import ButtonAppBar from './ButtonAppBar'
import {Fragment, useEffect} from "react";
import StatusBar from './StatusBar';
import * as S from './style';


const Header= ()=>{
    useEffect(()=>{
        console.log("header!!!")
    })
    return(
        <Fragment>
            <ButtonAppBar/>
            <S.ConnectionTool>
            <StatusBar/>
            </S.ConnectionTool>
        </Fragment>
);
};

export default Header;