const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/digitize"],
    exact: false,
    component: "Digitize",
  },
  {
    path: ["/adapters"],
    exact: false,
    component: "Adapters",
  },
  {
    path: ["/confirmation"],
    exact: false,
    component: "Confirmation",
  },
  {
    path: ["/myaccount"],
    exact: false,
    component: "MyAccount",
  },
  {
    path: ["/adapterconfirm"],
    exact: false,
    component: "AdapterConfirm",
  },
  {
    path: ["/convertconfirm"],
    exact: false,
    component: "ConvertConfirm",
  },
  {
    path: ["/converttonft"],
    exact: false,
    component: "ConvertToNft",
  },
];

export default routes;
