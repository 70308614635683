import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { PendingTransaction } from "../../../Components/PendingTransaction.js";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  rightButton: {
    marginRight: "2em",
    float: "right",
    position: "inherit !important",
  },
  rightButtons: {
    visibility: "inherit !important",
    flexGrow: "1",
  },
  toolBar: {
    display: "flex",
    width: "100%",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [accountEnable, setAccountEnable] = useState(false);
  const [poolAccess, setPoolAccess] = useState(false);
  const { pendingStatus, setPendingStatus } = useContext(PendingTransaction);

  const getConnectedAccount = async () => {
    try {
      const { ethereum } = window;
      const accounts = await ethereum.request({ method: "eth_accounts" });
      const chainId = await ethereum.request({ method: "eth_chainId" });
      return [accounts, chainId];
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    const { ethereum } = window;
    let activeAccounts = await getConnectedAccount();
    if (activeAccounts == undefined) {
      setAccountEnable(false);
    } else {
      if (activeAccounts[1] != "0x2a") {
        alert(
          "PandiFi is in testing.  Please connect to the Kovan Testnet to use the DAPP."
        );
      } else if (activeAccounts[0].length > 0) {
        setAccountEnable(true);
        setPoolAccess(true);
      } else {
        setPoolAccess(true);
        setAccountEnable(false);
      }

      ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setAccountEnable(true);
        } else {
          setAccountEnable(false);
        }
      });
    }
  }, []);

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolBar}>
        <Typography component={Link} color="inherit" to="/" variant="h6">
          PandiFi
        </Typography>
        <div className={classes.rightButtons}>
          <ButtonGroup className={classes.rightButton}>
            <Button
              disabled={!accountEnable || pendingStatus}
              color="inherit"
              component={Link}
              to="/digitize"
            >
              Digitize
            </Button>
            <Button
              disabled={!accountEnable || pendingStatus}
              color="inherit"
              component={Link}
              to="/myaccount"
            >
              My Assets
            </Button>
          </ButtonGroup>
          <Button
            disabled={!poolAccess}
            className={classes.rightButton}
            component={Link}
            to="/adapters"
            color="inherit"
          >
            Adapters
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}
