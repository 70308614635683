import { Switch, Route } from "react-router-dom";
import Header from '../Components/Header';
import {lazy, Fragment, Suspense, useState} from "react";
import routes from "./config";
import {PendingTransaction} from "../Components/PendingTransaction.js"

const Router = ()=> {
const [pendingStatus, setPendingStatus] = useState();

  return (
      <Suspense fallback={"loading"}>
    <Fragment>
    <PendingTransaction.Provider value={{ pendingStatus, setPendingStatus }}>
    <Header/>
    <Switch>
    {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
    </Switch>
    </PendingTransaction.Provider>
    </Fragment>
    </Suspense>
  );
}

export default Router;